import "dropzone/dist/dropzone.css";
import "./admin_form_file_field.css";
import { Controller as BaseController } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage";
import { Dropzone } from "dropzone";

Dropzone.autoDiscover = false;

export class Controller extends BaseController {
  static targets = ["dropzone", "input"];

  connect() {
    this.parseAndRemoveInput();
    this.initDropzone();
  }

  disconnect() {
    this.dropzone = null;
  }

  parseAndRemoveInput() {
    this.name = this.inputTarget.getAttribute("name");
    this.url = this.inputTarget.dataset.directUploadUrl;
    this.inputTarget.remove();
  }

  initDropzone() {
    this.dropzone = new Dropzone(this.dropzoneTarget, this.dropzoneOptions);
    this.dropzone.on("addedfile", file => this.uploadFile(file));
  }

  uploadFile(file) {
    this.currentFile = file;
    const upload = new DirectUpload(file, this.url, this);

    upload.create((error, blob) => {
      if (error) {
        console.error(error);
      } else {
        if (!this.multiple) this.removeHiddenFields();

        this.addHiddenField(blob);

        this.dropzone.emit("complete", file);
      }
    });
  }

  addHiddenField(blob) {
    const hidden = `<input type="hidden" name="${this.name}" value="${blob.signed_id}" />`;
    this.element.insertAdjacentHTML("beforebegin", hidden);
  }

  removeHiddenFields() {
    this.element
      .querySelectorAll(`input[name="${this.name}"]`)
      .forEach(el => el.parentNode.removeChild(el));
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", e =>
      this.directUploadDidProgress(e)
    );
  }

  directUploadDidProgress(event) {
    const progress = (event.loaded / event.total) * 100;
    this.dropzone.emit(
      "uploadprogress",
      this.currentFile,
      progress,
      event.loaded
    );

    if (event.loaded === event.total) {
      this.dropzone.emit("success", this.currentFile);
    }
  }

  get dropzoneOptions() {
    return {
      url: this.url,
      // TODO: pass this from back-end
      acceptedFiles: "image/*",
      autoQueue: false,
      dictDefaultMessage: this.element.dataset.defaultMessage,
      maxFiles: this.maxFiles,
      maxfilesexceeded: file => {
        this.dropzone.removeAllFiles();
        this.dropzone.addFile(file);
      }
    };
  }

  get maxFiles() {
    return this.multiple ? null : 1;
  }

  get multiple() {
    return this.element.dataset.multiple === "true";
  }
}
