import ClipboardJS from "clipboard";
import "./button.css";

document.addEventListener("DOMContentLoaded", () => {
  const clipboard = new ClipboardJS(".button.has-clipboard");

  clipboard.on("success", e => {
    const message = e.trigger.getAttribute("data-success");
    e.trigger.querySelector(".button-text").innerHTML = message;
    e.clearSelection();
  });
});
