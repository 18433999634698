const locales = {
  en: {
    bold: "Bold",
    bullets: "Bullets",
    byte: "Byte",
    bytes: "Bytes",
    captionPlaceholder: "Add a caption…",
    code: "Code",
    heading1: "Heading",
    indent: "Increase Level",
    italic: "Italic",
    link: "Link",
    numbers: "Numbers",
    outdent: "Decrease Level",
    quote: "Quote",
    redo: "Redo",
    remove: "Remove",
    strike: "Strikethrough",
    undo: "Undo",
    unlink: "Unlink",
    url: "URL",
    urlPlaceholder: "Enter a URL…",
    GB: "GB",
    KB: "KB",
    MB: "MB",
    PB: "PB",
    TB: "TB"
  },
  fr: {
    bold: "Gras",
    bullets: "Liste",
    byte: "Octet",
    bytes: "Octets",
    captionPlaceholder: "Ajouter légende…",
    code: "Code",
    heading1: "Titre",
    indent: "Agrandir retrait",
    italic: "Cursif",
    link: "Lien",
    numbers: "Énumération",
    outdent: "Réduire retrait",
    quote: "Citation",
    redo: "Refaire",
    remove: "Supprimer",
    strike: "Biffé",
    undo: "Annuler",
    unlink: "Supprimer lien",
    url: "URL",
    urlPlaceholder: "Saisir URL…",
    GB: "Go",
    KB: "Ko",
    MB: "Mo",
    PB: "Po",
    TB: "To"
  }
};

export default locales;
