import "trix/dist/trix.css";
import * as Trix from "trix";
import translations from "./trix_locales";

// Use `p` instead of `div`
// cf. https://github.com/basecamp/trix/issues/202#issuecomment-282306943
Trix.config.blockAttributes.default.tagName = "p";
Trix.config.blockAttributes.default.breakOnReturn = true;

const translation = translations[document.documentElement.lang];
if (translation) {
  Trix.config.lang = translation;
}

const { lang } = Trix.config;

Trix.config.toolbar.getDefaultHTML = () => `
  <div class="trix-button-row">
    <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
      <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="${
        lang.bold
      }" tabindex="-1">${lang.bold}</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="${
        lang.italic
      }" tabindex="-1">${lang.italic}</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-strike is-not-in-light" data-trix-attribute="strike" title="${
        lang.strike
      }" tabindex="-1">${lang.strike}</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-link is-not-in-light" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="${
        lang.link
      }" tabindex="-1">${lang.link}</button>
    </span>
    <span class="trix-button-group trix-button-group--block-tools is-not-in-light" data-trix-button-group="block-tools">
      <button type="button" class="trix-button trix-button--icon trix-button--icon-quote" data-trix-attribute="quote" title="${
        lang.quote
      }" tabindex="-1">${lang.quote}</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-code" data-trix-attribute="code" title="${
        lang.code
      }" tabindex="-1">${lang.code}</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-bullet-list" data-trix-attribute="bullet" title="${
        lang.bullets
      }" tabindex="-1">${lang.bullets}</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-number-list" data-trix-attribute="number" title="${
        lang.numbers
      }" tabindex="-1">${lang.numbers}</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-decrease-nesting-level" data-trix-action="decreaseNestingLevel" title="${
        lang.outdent
      }" tabindex="-1">${lang.outdent}</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-increase-nesting-level" data-trix-action="increaseNestingLevel" title="${
        lang.indent
      }" tabindex="-1">${lang.indent}</button>
    </span>
    <span class="trix-button-group-spacer"></span>
    <span class="trix-button-group trix-button-group--history-tools" data-trix-button-group="history-tools">
      <button type="button" class="trix-button trix-button--icon trix-button--icon-undo" data-trix-action="undo" data-trix-key="z" title="${
        lang.undo
      }" tabindex="-1">${lang.undo}</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-redo" data-trix-action="redo" data-trix-key="shift+z" title="${
        lang.redo
      }" tabindex="-1">${lang.redo}</button>
    </span>
  </div>
  <div class="trix-dialogs is-not-in-light" data-trix-dialogs>
    <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
      <div class="trix-dialog__link-fields">
        <input type="url" name="href" class="trix-input trix-input--dialog" placeholder="${
          lang.urlPlaceholder
        }" aria-label="${lang.url}" required data-trix-input>
        <div class="trix-button-group">
          <input type="button" class="trix-button trix-button--dialog" value="${
            lang.link
          }" data-trix-method="setAttribute">
          <input type="button" class="trix-button trix-button--dialog" value="${
            lang.unlink
          }" data-trix-method="removeAttribute">
        </div>
      </div>
    </div>
  </div>
`;
