/* global gon */
import "./admin_search.css";
import { Controller as BaseController } from "@hotwired/stimulus";
import "instantsearch.css/themes/reset.css";
// import "instantsearch.css/themes/algolia.css";
import algoliasearch from "algoliasearch/lite";
import instantsearch from "instantsearch.js";
import { configure, searchBox, pagination } from "instantsearch.js/es/widgets";
import { connectHits } from "instantsearch.js/es/connectors";
import hitTemplates from "./hit_templates";

export class Controller extends BaseController {
  static targets = ["searchBox", "hits", "pagination"];

  connect() {
    this.initAlgolia();
    this.initWidgets();
    this.initHits();
    this.search.start();
  }

  disconnect() {
    this.search.dispose();
  }

  initAlgolia() {
    const searchClient = algoliasearch(
      gon.global.algolia.application_id,
      gon.global.algolia.api_key
    );

    const hitsContainer = this.hitsTarget;
    const paginationContainer = this.paginationTarget;
    const pageableType = this.data.get("pageableType");
    const indexKey = this.data.get("index") || "pages";
    const { originalItems } = this;

    this.search = instantsearch({
      indexName: `${gon.global.algolia.indices[indexKey]}_${gon.locale}`,
      searchFunction(helper) {
        if (helper.state.query === "") {
          hitsContainer.style.display = "none";
          paginationContainer.style.display = "none";
          originalItems.style.display = "";
        } else {
          hitsContainer.style.display = "";
          paginationContainer.style.display = "";
          originalItems.style.display = "none";
        }

        helper.search();
      },
      searchClient
    });

    if (pageableType) {
      this.search.addWidget(
        configure({
          facetFilters: `pageable_type:${pageableType}`
        })
      );
    }
  }

  initWidgets() {
    this.search.addWidget(
      searchBox({
        container: this.searchBoxTarget,
        showLoadingIndicator: false
      })
    );

    const paginationContainer = this.paginationTarget;
    this.search.addWidget(
      pagination({
        container: paginationContainer,
        cssClasses: {
          root: "admin-pagination"
        }
      })
    );
  }

  initHits() {
    const renderHits = renderOptions => {
      const { hits, widgetParams } = renderOptions;
      const template = hitTemplates[this.templateKey];

      widgetParams.container.innerHTML = hitTemplates[
        `${this.templateKey}_container`
      ](hits, template);
    };

    const customHits = connectHits(renderHits);

    this.search.addWidget(
      customHits({
        container: this.hitsTarget
      })
    );
  }

  hitTemplate(hit) {
    if (!document.hitTemplate) return this.defaultTemplate(hit);

    return document.hitTemplate(hit);
  }

  get templateKey() {
    return this.data.get("index") || "pages";
  }

  get defaultTemplate() {
    return hit => `<a href="${hit.path}">
      ${instantsearch.highlight({ attribute: "title", hit })}
    </a>`;
  }

  get originalItems() {
    return document.querySelector(
      ".admin-items-table, .admin-items-tree, .admin-items-grid"
    );
  }
}
