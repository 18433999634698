import "./toc.css";
import { Controller as BaseController } from "@hotwired/stimulus";
import "intersection-observer";
import scrollama from "scrollama";

export class Controller extends BaseController {
  static targets = ["container", "step", "highlight", "arrow"];

  connect() {
    // If the toc is already visible, stop here
    if (this.containerTarget.classList.contains("is-visible")) return;

    const scrollerAppear = scrollama();
    scrollerAppear
      .setup({
        step: this.containerTargets,
        offset: 0.5
      })
      .onStepEnter(response => response.element.classList.add("is-visible"));

    const scroller = scrollama();
    scroller
      .setup({
        step: this.stepTargets,
        offset: 0.1
      })
      .onStepEnter(e => this.handleStepEnter(e))
      .onStepExit(e => this.handleStepExit(e));
  }

  handleStepEnter(response) {
    this.highlightTargets.forEach((el, i) => {
      el.classList.toggle("is-active", response.index === i);
    });
  }

  handleStepExit(response) {
    if (response.direction === "up") {
      this.highlightTargets.forEach((el, i) => {
        el.classList.toggle("is-active", response.index - 1 === i);
      });
    }
  }
}
