import "./header.css";
import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  open() {
    this.element.classList.add("is-open");
    document.body.classList.add("is-not-scrollable");
  }

  close() {
    this.element.classList.remove("is-open");
    document.body.classList.remove("is-not-scrollable");
  }
}
