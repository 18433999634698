import "./admin_blocks_external_link.css";
import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ["fields"];

  connect() {}

  add(event) {
    const time = new Date().getTime();
    const regexp = new RegExp(event.currentTarget.dataset.id, "g");
    const newField = event.currentTarget.dataset.fields.replace(regexp, time);
    this.fieldsTarget.insertAdjacentHTML("beforeend", newField);
    event.preventDefault();
  }
}
