import "./entries.css";
import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ["items"];

  loadMore(e) {
    e.preventDefault();
    const link = e.target.closest("a");
    const url = new URL(link.href);
    const currentItems = this.itemsTarget.innerHTML;

    fetch(url, {
      headers: {
        "X-Requested-With": "XMLHttpRequest"
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("Server Error");
        }

        response.text().then(text => {
          this.element.innerHTML = text;
          this.itemsTarget.insertAdjacentHTML("afterbegin", currentItems);
        });
      })
      .catch(error => {
        console.error(error);
      });
  }
}
