import "./cards_slider.css";
import { Controller as BaseController } from "@hotwired/stimulus";
import "intersection-observer";
import Flickity from "flickity";
import "flickity/dist/flickity.css";
import scrollama from "scrollama";

export class Controller extends BaseController {
  static targets = ["carousel"];

  connect() {
    this.scroller = scrollama();
    this.scroller
      .setup({
        offset: 0.9,
        once: true,
        step: this.carouselTargets,
      })
      .onStepEnter(response => response.element.classList.add("is-visible"));

    this.flickity = new Flickity(this.carouselTarget, {
      groupCells: true,
      pageDots: false,
      setGallerySize: false,
    });
  }

  disconnect() {
    if (this.scroller) {
      this.scroller.destroy();
    }
    if (this.flickity) {
      this.flickity.destroy();
    }
  }
}
