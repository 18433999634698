import instantsearch from "instantsearch.js";

const actionTd = (key, id) => `<td class="admin-items-table-actions">
  <a class="admin-button has-outline is-secondary is-default-action"
    href="/admin/${key}/${id}/edit">
    <span class="admin-button-text">Éditer</span>
  </a>
  <a class="admin-button as-link is-secondary" data-confirm="Are you sure?"
    rel="nofollow" data-method="delete"
    href="/admin/${key}/${id}">
    <span class="admin-button-text">Supprimer</span>
  </a>
</td>`;

export default {
  attachments_container: (hits, template) => `
    <div class="admin-items-grid" data-controller="admin-items-grid">
      ${hits.map(template).join("")}
    </div>`,

  attachments: hit => `<a href="${hit.admin_path}" class="admin-media"
    data-tid="${hit.objectID}" data-action="admin-form-media-select#addMedia">
    <img src="${hit.thumbnail}" />
    <div class="admin-media-content">
      <strong class="admin-media-title" title="${hit.title}">
        ${instantsearch.highlight({ attribute: "title", hit })}
      </strong>
      <div class="admin-media-text">${hit.details}</div>
    </div>
  </a>`,

  events_container: (hits, template) => `
    <table class="admin-items-table" data-controller="admin-items-table" cellspacing="0">
      <thead><tr><th>Titre</th><th>Date(s)</th><th>Catégorie</th><th></th></tr></thead>
      <tbody data-action="click->admin-items-table#clickBody">
        ${hits.map(template).join("")}
    </tbody></table>`,

  events: hit => `<tr>
    <th>${instantsearch.highlight({ attribute: "title", hit })}</th>
    <td>${hit.date}</td>
    <td>${hit.category ? hit.category : "<em>Aucun</em>"}</td>
    ${actionTd("events", hit.objectID)}
  </tr>`,

  people_container: (hits, template) => `
    <table class="admin-items-table" data-controller="admin-items-table" cellspacing="0">
      <thead><tr><th>Nom complet</th><th>Type</th><th></th></tr></thead>
      <tbody data-action="click->admin-items-table#clickBody">
        ${hits.map(template).join("")}
    </tbody></table>`,

  people: hit => `<tr>
    <th>${instantsearch.highlight({ attribute: "full_name", hit })}</th>
    <td>
      ${hit.ferdi_member ? "Membre de la Ferdi" : ""}
      ${hit.associated_member ? "Chercheur associé" : ""}
      ${!hit.ferdi_member && !hit.associated_member ? "<em>Aucun</em>" : ""}
    </td>
    ${actionTd("people", hit.objectID)}
  </tr>`,

  publications_container: (hits, template) => `
    <table class="admin-items-table" data-controller="admin-items-table" cellspacing="0">
      <thead><tr><th>Titre</th><th>Catégorie</th><th>Publié le</th><th></th></tr></thead>
      <tbody data-action="click->admin-items-table#clickBody">
        ${hits.map(template).join("")}
    </tbody></table>`,

  publications: hit => `<tr>
    <th>${instantsearch.highlight({ attribute: "title", hit })}</th>
    <td>${hit.category}</td>
    <td>${hit.published_at}</td>
    ${actionTd("publications", hit.objectID)}
  </tr>`
};
