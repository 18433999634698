import "./curriculum/admin_blocks_curriculum";
import "./download/admin_blocks_download";
import "./embed/admin_blocks_embed";
import "./external_link/admin_blocks_external_link";
import "./frame/admin_blocks_frame";
import "./image/admin_blocks_image";
import "./internal_link/admin_blocks_internal_link";
import "./rich_text/admin_blocks_rich_text";
import "./testimonial/admin_blocks_testimonial";
import "./title/admin_blocks_title";
