import "./showcase.css";
import { Controller as BaseController } from "@hotwired/stimulus";
import "iframe-resizer";

export class Controller extends BaseController {
  connect() {
    window.iFrameResize({}, "#showcase-featured-item-iframe");

    setTimeout(
      () => {
        const iframe = document.querySelector("#showcase-featured-item-iframe");
        if (iframe) {
          iframe.iFrameResizer.resize();
        }
      },
      10000
    );
  }
}
