import "./admin_form_html_area.css";
import { Controller as BaseController } from "@hotwired/stimulus";
import "../../trix_config";

export class Controller extends BaseController {
  static targets = ["editor"];

  initialize() {
    this.setEditorConfig();

    this.editorTarget.addEventListener(
      "trix-initialize",
      () => this.setEditorConfig
    );
  }

  setEditorConfig() {
    const config = this.data.get("config");

    if (config === "light") {
      this.element.querySelectorAll(".is-not-in-light").forEach(el => {
        el.remove();
      });
    }
  }
}
