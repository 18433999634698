import "./newsletter.css";
import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ["form", "input", "text", "status"];

  register(event) {
    event.preventDefault();
    this.statusTarget.innerHTML = "";

    this.registerToNewsletter(this.formTarget).then(data => {
      if (data.subscribed) {
        this.textTarget.innerHTML = data.message;
        this.formTarget.style.display = "none";
      } else {
        this.statusTarget.innerHTML = data.message;
        this.inputTarget.focus();
        this.formTarget.style.display = "flex";
      }
    });
  }

  async registerToNewsletter() {
    const token = document.querySelector("meta[name='csrf-token']").content;
    const formData = new FormData(this.formTarget);
    formData.append("authenticity_token", token);

    const response = await fetch(this.formTarget.action, {
      method: "POST",
      headers: {
        "X-CRSF-Token": token
      },
      credentials: "same-origin",
      body: formData
    });

    const data = await response.json();

    return data;
  }
}
