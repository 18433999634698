import "./admin_form_multiselect.css";
import { Controller as BaseController } from "@hotwired/stimulus";
import "autocomplete-js/dist/autocomplete.css";
import Autocomplete from "autocomplete-js";
import feather from "feather-icons";
import Sortable from "sortablejs";

export class Controller extends BaseController {
  static targets = ["input", "items", "position", "template"];

  connect() {
    this.connectAutocomplete();
    this.connectSortable();
  }

  connectAutocomplete() {
    const options = {
      Url: this.data.get("url"),
      _Select: this.addItem.bind(this)
    };

    Autocomplete(options, this.inputTarget);
  }

  connectSortable() {
    const options = {
      handle: ".js-admin-form-multiselect-handle",
      onUpdate: this.updateItemPositions.bind(this)
    };

    Sortable.create(this.itemsTarget, options);
  }

  /* eslint-disable no-param-reassign */
  updateItemPositions() {
    this.positionTargets.forEach((item, i) => {
      item.value = i + 1;
    });
  }
  /* eslint-enable no-param-reassign */

  addItem(el) {
    const index = this.positionTargets.length;

    const content = this.templateTarget.innerHTML
      .replace(/NEW_ITEM/g, index)
      .replace(/ITEM_POSITION/g, index + 1)
      .replace(/ITEM_LABEL/g, el.innerText)
      .replace(
        /ITEM_ATTRIBUTE_ID/g,
        el.getAttribute("data-autocomplete-value")
      );

    this.inputTarget.value = "";
    this.itemsTarget.insertAdjacentHTML("beforeend", content);
    feather.replace();
  }

  // eslint-disable-next-line class-methods-use-this
  destroyItem(e) {
    const destroyModifier = "is-destroyed";
    const item = e.target.closest(".admin-form-multiselect-item");
    const toggle = !item.classList.contains(destroyModifier);
    item.classList.toggle(destroyModifier, toggle);
    const destroyInput = item.querySelector(
      ".js-admin-form-multiselect-destroy"
    );
    destroyInput.value = toggle.toString();
  }
}
