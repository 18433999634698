import instantsearch from "instantsearch.js";
import I18n from "../../../app/javascript/i18n";

const icons = ["bars", "curve", "line"];

const entryText = hit => {
  if (!hit.description && !hit.introduction && !hit.content) {
    return "";
  }

  return `<div class="entry-text has-highlight">
    ${instantsearch.snippet({ attribute: "description", hit })}
    ${instantsearch.snippet({ attribute: "introduction", hit })}
    ${instantsearch.snippet({ attribute: "content", hit })}
  </div>`;
};

const highlightedTags = hit => {
  // eslint-disable-next-line no-underscore-dangle
  const highlightTags = hit._highlightResult.tags;
  return hit.tags
    .map(
      (tag, i) =>
        `<li class="tags-item"><a href="${tag.href}">
        ${highlightTags ? highlightTags[i].text.value : tag.text}
      </a></li>`
    )
    .join("");
};

export default {
  events: {
    item: hit => `
      <div class="agenda-item without-borders">
        <a class="agenda-link is-small" href="${hit.path}">
          <div class="agenda-date-wrapper">
            <div class="agenda-date">${hit.human_date}</div>
          </div>
          <div class="agenda-content">
            <div class="agenda-headlines has-highlight">
              <span class="agenda-title">
                ${instantsearch.highlight({ attribute: "title", hit })}
              </span>
              <span class="agenda-location">
                ${instantsearch.highlight({ attribute: "place", hit })}
              </span>
            </div>
          </div>
          ${
            hit.image
              ? `<div class="agenda-image"><img src="${hit.image}"></div>`
              : ""
          }
        </a>
        <div class="tags is-in-agenda has-highlight"><ul class="tags-items">
          ${highlightedTags(hit)}
        </ul></div>
      </div>`
  },
  content: {
    item: hit => `
      <div class="entry">
        <div class="entry-content">
          <a href="${hit.path || hit.url}"${
      hit.url
        ? ` target="_blank" rel="noopener" class="is-external" title="${I18n.t(
            "search.ui.new_window"
          )}"`
        : ""
    }>
            <div class="entry-title has-highlight">
              ${instantsearch.highlight({ attribute: "title", hit })}${
      hit.url
        ? `<svg class="icon icon-open"><use xlink:href="#icon-open"></use></svg>`
        : ""
    }
            </div>
            <div class="entry-category">${hit.human_kind}</div>
          </a>
          ${entryText(hit)}
          ${hit.date ? `<div class="entry-date">${hit.date}</div>` : ""}
          <div class="tags is-in-entry has-highlight"><ul class="tags-items">
            ${highlightedTags(hit)}
          </ul></div>
        </div>
      </div>`
  },
  news_flashes: {
    item: hit => `
      <div class="entry is-indicator has-icon">
        <a class="entry-image" href="${hit.url}">
          <svg class="icon icon-flash"><use xlink:href="#icon-flash"></use></svg>
          </a>
          <div class="entry-content">
          <a href="${hit.path}">
            <div class="entry-title has-highlight">
              ${instantsearch.highlight({ attribute: "title", hit })}
              <svg class="icon icon-open"><use xlink:href="#icon-open"></use></svg>
            </div>
          </a>
          ${entryText(hit)}
          ${
            hit.human_date
              ? `<div class="entry-kind-and-date"><span class="entry-date">${
                  hit.human_date
                }</span></div>`
              : ""
          }
          <div class="tags is-in-entry has-highlight"><ul class="tags-items">
            ${highlightedTags(hit)}
          </ul></div>
        </div>
      </div>`
  },
  pages: {
    item: hit => `
      <div class="entry">
        <div class="entry-content">
          <a href="${hit.path}">
            <div class="entry-title has-highlight">
              ${instantsearch.highlight({ attribute: "title", hit })}
            </div>
            <div class="entry-category">${hit.human_kind}</div>
          </a>
          ${entryText(hit)}
          ${hit.date ? `<div class="entry-date">${hit.date}</div>` : ""}
          <div class="tags is-in-entry has-highlight"><ul class="tags-items">
            ${highlightedTags(hit)}
          </ul></div>
        </div>
      </div>`
  },
  indicators: {
    // TODO: add authors
    // TODO: add human date
    item: hit => {
      const icon = icons[Math.floor(Math.random() * icons.length)];
      return `
      <div class="entry is-indicator has-icon">
        <a class="entry-image" href="${hit.path}">
          <svg class="icon icon-data-${icon}">
            <use xlink:href="#icon-data-${icon}"></use>
          </svg>
        </a>
        <div class="entry-content">
          <a href="${hit.path}">
            <div class="entry-title has-highlight">
              ${instantsearch.highlight({ attribute: "title", hit })}
            </div>
          </a>
          ${entryText(hit)}
          ${hit.date ? `<div class="entry-date">${hit.date}</div>` : ""}
          <div class="tags is-in-entry has-highlight"><ul class="tags-items">
            ${highlightedTags(hit)}
          </ul></div>
        </div>
      </div>`;
    }
  },
  people: {
    item: hit => `
      <div class="team-item without-borders">
        <a class="team-item-content" href="${hit.path}">
          <div class="team-details has-highlight">
            <div class="team-name">
              ${instantsearch.highlight({ attribute: "full_name", hit })}
              <svg class="icon icon-angle-right"><use xlink:href="#icon-angle-right"></use></svg>
            </div>
            <div class="team-title">
              ${instantsearch.highlight({ attribute: "title", hit })}
            </div>
            <div class="team-subtitle">
              ${instantsearch.highlight({ attribute: "sub_title", hit })}
            </div>
          </div>
          <div class="team-image">
            <img alt="" src="${hit.image}">
          </div>
        </a>
      </li>`
  },
  publications: {
    item: hit => `
      <div class="entry is-publication${
        hit.emphasized ? " is-emphasized" : ""
      }">
        <a class="entry-image" href="${hit.path}">
          <img alt="" src="${hit.image}">
        </a>
        <div class="entry-content">
          <a href="${hit.path}">
            <div class="entry-title has-highlight">
              ${instantsearch.highlight({ attribute: "title", hit })}
            </div>
          </a>
          ${
            hit.authors.length || hit.reference
              ? `<div class="authors">
                ${
                  hit.authors.length ? I18n.t("search.ui.by") : ""
                } ${hit.authors
                  .map(author => `<a href="${author.href}">${author.name}</a>`)
                  .join(", ")}
                ${
                  hit.reference
                    ? `<span class="authors-suffix">${hit.reference}</span>`
                    : ""
                }
                </div>`
              : ""
          }
          ${
            hit.published_at_human
              ? `<div class="entry-date">${hit.published_at_human}</div>`
              : ""
          }
          ${
            hit.emphasized
              ? `<a class="button" href="${hit.path}">
                <span class="button-text">${I18n.t(
                  "search.ui.know_more"
                )}</span>
                <svg class="icon icon-arrow-right button-icon"><use xlink:href="#icon-arrow-right"></use></svg>
              </a>`
              : ""
          }
          <div class="tags is-in-entry has-highlight"><ul class="tags-items">
            ${highlightedTags(hit)}
          </ul></div>
        </div>
      </div>`
  },
  trainings: {
    item: hit => `
      <div class="entry">
        <a class="entry-image" href="${hit.path}"><img alt="" src="${
      hit.image
    }"></a>
        <div class="entry-content">
          <a href="${hit.path}">
            <div class="entry-title has-highlight">
              ${instantsearch.highlight({ attribute: "title", hit })}
              <span class="entry-place">${instantsearch.highlight({
                attribute: "place",
                hit
              })}</span>
            </div>
          </a>
          <ul class="entry-bullet-points">
            ${
              hit.formats.length
                ? hit.formats
                    .map(
                      format =>
                        `<li class="entry-bullet-points-item">${format}</li>`
                    )
                    .join("")
                : ""
            }
            <li class="entry-bullet-points-item">${hit.human_date}</li>
          </ul>
          ${
            hit.people.length
              ? `<div class="entry-list">
                <span class="entry-list-label">Intervenants : </span>
                <div class="authors">
                  ${hit.people
                    .map(
                      person => `<a href="${person.href}">${person.name}</a>`
                    )
                    .join(", ")}
                </div>
              </div>`
              : ""
          }
            ${
              hit.partners.length
                ? `<div class="entry-list">
                  <span class="entry-list-label">En partenariat avec : </span>
                  ${hit.partners.join(", ")}
                </div>`
                : ""
            }
            <div class="tags is-in-entry has-highlight"><ul class="tags-items">
              ${highlightedTags(hit)}
            </ul></div>
          </div>
        </div>
      </div>`
  }
};
